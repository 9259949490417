import { useEffect, useState } from 'react';
import { Button, Select, Label, Heading, Input, Infotip } from '@auspost/postmaster-react';
import styles from './ProductAnalysisDetailed.module.scss';
import DatePicker from '../components/DatePicker';
import portalClient from '../utils/PortalClient';
import { Spinner } from '../components/Shared';
import { emptyProduct } from '../components/organisms/ProductDetail';
import ProductSelect from '../components/molecules/ProductSelect';
import DenominationSelect from '../components/molecules/DenominationSelect';
import { downloadBlob, validateDates } from '../utils/utils';
import moment from 'moment';

const ProductAnalysisDetailed = ({partner}: {partner: any}) => {
    const [product, setProduct] = useState(emptyProduct);
    const [denomination, setDenomination] = useState<any | null>(null);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [records, setRecords] = useState<any[]| null>(null);
    const [searching, setSearching] = useState(false);
    const [searchMessage, setSearchMessage] = useState<string>("");
    const [searchResultMessage, setSearchResultMessage] = useState<string>("");
    const [statuses, setStatuses] = useState<any[]>([]);
    const [status, setStatus] = useState<number>(-1);
    const [workcentreId, setWorkcentreId] = useState<string>("");
    const [controlCode, setControlCode] = useState<string>("");
    const [allowProducts, setAllowProducts] = useState<any[] | null>(null); // all products for the current partner without "-1: All Products"

    useEffect(() => {
        let endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        let startDate = new Date(endDate.setDate(endDate.getDate() - 7));
        endDate = new Date();
        console.log("Start/End date", startDate, endDate);
        setFromDate(startDate);
        setToDate(endDate);
    }, []);

    useEffect(() => {
        setStatuses([{label: "All", value: -1}, 
            ...(partner?.voucherStatuses ?? []).map(
                (s:any) => { return {value: s.id, label: s.description}})]);
    }, [partner?.voucherStatuses]);

    useEffect(() => {
        if(!searching)
            setSearchMessage("");
    }, [searching])

    const doExport = async () => {
        if(!fromDate || !toDate || !validateDates(fromDate, toDate))
            return;
        if(!(Math.abs(moment(toDate).diff(moment(fromDate), "days"))<=31)){ 
            alert("The selected date range can not exceed 31 days");
            return;
        }
        // console.log('Exporting...', fromDate?.toJSON(), toDate?.toJSON());
        const strFromDate = moment.utc(moment(fromDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        const strToDate = moment.utc(moment(toDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        console.log("Exporting1...", strFromDate, strToDate);
        setSearching(true);
        setSearchMessage("Exporting...");
        try {            
            // let response = await portalClient.get(`/api/portal/productdetailreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
            //         `&date_gte=${fromDate?.toJSON()}&date_lte=${toDate?.toJSON()}&saleLocation=${workcentreId}&controlCode=${controlCode}`+
            //         `&denominationId=${denomination?.DenominationID}&voucherStatus=${status}&limit=-1`);
            let response = await portalClient.get(`/api/portal/productdetailreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
                    `&date_gte=${strFromDate}&date_lte=${strToDate}&saleLocation=${workcentreId}&controlCode=${controlCode}`+
                    `&denominationId=${denomination?.DenominationID}&voucherStatus=${status}&limit=-1`);
            console.log('Got productanlysisreport:', response.data);            
            let details = response.data as any[];
            console.log('Got details for productanlysisreport:', details, details?.length);
            if(details?.length === 0){
                setSearchResultMessage("No records found");
                setSearchMessage("");
                setSearching(false);
                return;
            }
            let doneHeaders = false;
            let exportDetail:string = "";
            for(let detail of details) {
                let row = {...detail};
                delete row.StatusCode;
                delete row.ErrorMessage;
                delete row.TotalRecordCount;
                if(!doneHeaders)
                {
                    console.log("Adding headers", row );
                    exportDetail += Object.keys(row).join(',') + "\n";
                    doneHeaders = true;
                }
                exportDetail += Object.values(row).join(',') + "\n";
            }
            const blob = new Blob([exportDetail],{ type: 'text/csv' });
            downloadBlob(blob, `productanalysisdetails-${partner.PartnerID}-${moment(fromDate).format("YYYYMMDD")}-${moment(toDate).format("YYYYMMDD")}.csv`);
            setSearchResultMessage(`Finished exporting ${details?.length ?? 0} records`);
            setSearchMessage("");
            setSearching(false);
        }
        catch(e) {
            alert("Error getting details");
            console.error("Error getting details", e);
            setSearching(false);
        };        
    }

    const doSearch = async () => {
        if(!fromDate || !toDate || !validateDates(fromDate, toDate))
            return;
        console.log("doSearch: fromDate, toDate", fromDate, toDate);
        console.log("diff months: " + Math.abs(moment(toDate).diff(moment(fromDate), "months")));
        if(!(Math.abs(moment(toDate).diff(moment(fromDate), "days"))<=31)){ 
            alert("The selected date range can not exceed 31 days");
            return;
        }
        // console.log('Searching...', fromDate?.toJSON(), toDate?.toJSON());
        const strFromDate = moment.utc(moment(fromDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        const strToDate = moment.utc(moment(toDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss.SSS');
        console.log("Searching1...", strFromDate, strToDate);
        setRecords(null);
        setSearchResultMessage("");
        setSearching(true);
        setSearchMessage("Searching...");
        try {
            // let response = await portalClient.get(`/api/portal/productdetailreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
            //         `&date_gte=${fromDate?.toJSON()}&date_lte=${toDate?.toJSON()}&saleLocation=${workcentreId}&controlCode=${controlCode}`+
            //         `&denominationId=${denomination?.DenominationID}&voucherStatus=${status}&limit=-1`);
            let response = await portalClient.get(`/api/portal/productdetailreport?partnerId=${partner?.PartnerID}&productId=${product.ProductID}`+
                    `&date_gte=${strFromDate}&date_lte=${strToDate}&saleLocation=${workcentreId}&controlCode=${controlCode}`+
                    `&denominationId=${denomination?.DenominationID}&voucherStatus=${status}&limit=-1`);
            console.log('Got productanlysisreport:', response.data);
            setRecords(response.data);
            if(response?.length === 0)
                setSearchResultMessage("No records found");
            setSearchMessage("");
            setSearching(false);
        } catch(e) {
            alert("Error getting productAnalysisSummaries");
            console.error("Error getting productAnalysisSummaries", e);
            setSearching(false);
        }
    }


    const doLoadWC = async (record:any) => {
        if(record.WCName || !records?.length)
            return;
        let wcName = "Unknown WC";
        try {
            let response = await portalClient.get(`/api/portal/workcentre/${record.WCID}`);
            //console.log('Got workcentre details:', response.data);
            if(response.data[0].StatusCode === 1) {
                wcName = response.data[0].ErrorMessage;
            }
            else {
                wcName = response.data[0].strWCDescription;
            }
        } catch(e) {
            console.error("Error loading workcentre", e);
            wcName = "Error loading WC";
        }
        // insert into collection so update reflects
        let newRecords = [...records];
        var foundIndex = newRecords.findIndex(x => x.WCID === record.WCID);
        newRecords[foundIndex] = {...record, WCName: wcName};
        console.log('newRecords', newRecords);
        setRecords(newRecords);
    }

    return (
        <>
            <div className={`main container ${styles.productAnalysisDetailed}`}>
                {/*<Heading level="h1">Product Analysis Detailed Report</Heading>*/}
                <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h3">
                                Product Analysis Details
                            </Heading>
                            {<div className="actions">
                                <Button size="small" variant="primary" onClick={doExport}>Export</Button>
                                <Button size="small" variant="primary" onClick={doSearch}>Search</Button>
                            </div>}
                        </div>
                    </div>
                    <div className="table-content">
                        <div className="flex-container row fields">
                            <div className="flex-row">
                                {/* allow all products: added allowAll */}
                                <ProductSelect className="flex-cell-4" product={product} setProduct={setProduct} partner={partner} allowAll={true} setAllowProducts={setAllowProducts} />
                                <div className="flex-cell-3">
                                    <label>Start Date</label>
                                    <div className="flex-container">                                        
                                        {fromDate && <DatePicker date={fromDate} setDate={setFromDate} className="flex-cell-fill" />}
                                    </div>
                                </div>
                                <div className="flex-cell-3">
                                    <label>End Date</label>
                                    <div className="flex-container">
                                        {toDate && <DatePicker date={toDate} setDate={setToDate} className="flex-cell-fill" />}
                                    </div>
                                </div>                                
                                <div className="flex-cell-2">
                                    <Label>Status</Label>
                                    <Select options={statuses} value={status}
                                        onChange={(v) => { 
                                            //setRecords(null);
                                            let val = statuses.find((d: any) => d.value.toString() === v.target.value); 
                                            //console.log("selected val, v.target:", val, v.target.value, statuses);
                                            setStatus(val?.value ?? -1);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-cell-4">
                                    {/* allow all denomination: added allowAll */}
                                    <DenominationSelect version={1} className='flex-cell-fill' denomination={denomination} product={product} setDenomination={setDenomination} allowAll={true} allowProducts={allowProducts} />
                                </div>
                                <div className="flex-cell-4">
                                    <Label>Workcentre ID</Label>
                                    <Input value={workcentreId} onChange={(e) => setWorkcentreId(e.target.value)} type="text" className='small' />
                                </div>
                                <div className="flex-cell-4">
                                    <Label>Control Code</Label>
                                    <Input value={controlCode} onChange={(e) => setControlCode(e.target.value)} type="text" className='small' />
                                </div>
                                <div className='flex-cell-fill'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {searching ? <><Spinner />{searchMessage}</>
                : !searchResultMessage ?
                    <><div className="table-container">
                        <div>&nbsp;</div>
                        <div>
                        <table className='table-content' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className="row header">
                                        <th>Date</th><th>Product</th><th>Denomination</th><th>Work Centre Code / Outlet</th><th>Control Code</th></tr>
                                    {records?.map((s: any) =>
                                        <tr key={s.id} className="row">
                                            <td>{s.DateSold}</td>
                                            <td>{s.ProductName}</td><td>{s.Denomination}</td>
                                            <td>
                                                {s.WCID}
                                                <Infotip onToggle={() => doLoadWC(s)} size="large">
                                                    {s.WCName ? s.WCName : <Spinner/>}
                                                </Infotip>
                                            </td>
                                            <td>{s.ControlCode}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div><br />
                    </div><br />                    
                </>
                : <div className="table-container">
                    <div className="header-container">
                        <div className="header">
                            <Heading level="h4">
                                {searchResultMessage}
                            </Heading>
                        </div>
                    </div>                      
                </div>}
            </div>
        </>
    );
}

export default ProductAnalysisDetailed;
